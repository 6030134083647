1<template>
  <div class="">
    <vue-ueditor-wrap v-model="msg" :config="editorConfig" editor-id="editor-demo-01"></vue-ueditor-wrap>
  </div>
</template>


<script>
import { serverUrl } from '@/mixins/config.js'
import VueUeditorWrap from 'vue-ueditor-wrap'
export default {
  components: { VueUeditorWrap },
  data() {
    return {
      msg: '',
    }
  },
  props: ['content'],
  watch: {
    msg(newval, oldval) {
      this.$emit('update:content', newval)
    },
    content: {
      handler(newval) {
        // console.log(this.content)
        if (newval) this.msg = newval
      },
      immediate: true,
    },
  },
  created() {
    console.log('editor 初始化')
    this.init_editor()
  },
  methods: {
    init_editor() {
      // 更多 UEditor 配置，参考 http://fex.baidu.com/ueditor/#start-config
      this.editorConfig = {
        // 访问 UEditor 静态资源的根路径，可参考 https://hc199421.gitee.io/vue-ueditor-wrap/#/faq
        UEDITOR_HOME_URL: '/UEditor/',
        // 服务端接口（这个地址是我为了方便各位体验文件上传功能搭建的临时接口，请勿在生产环境使用！！！）
        //serverUrl: '//192.168.2.92:81/api/sysFileInfo/upload',//'//ueditor.szcloudplus.com/cos',////`http://192.168.2.93:8080/api/sysFileInfo/upload`
        serverUrl: `//${serverUrl}:8080/ueditor/server`,
        initialFrameHeight: 500,
        autoHeightEnabled: false,
        readonly: true,
      }
    },
  },
}
</script>


<style lang="less" scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.font {
  font-size: 16px;
}
.drhj {
  margin: 10px 0 27px;
}
.zjdr {
  font-size: 18px;
  font-weight: bold;
  color: #3e3d3d;
}

::v-deep .edui-editor {
  z-index: unset !important;
}
</style>